<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">Progiciel</h2>
        </b-link>

        <b-card-title class="mb-1">
          Bienvenu sur votre Progiciel Bêta 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Veuillez vous connecter à votre compte pour continuer
        </b-card-text>
        <small class="text-danger">{{ errorMessage }}</small>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- Type -->
            <b-form-group label="Entreprise" label-for="company">
              <validation-provider
                #default="{ errors }"
                name="Company"
                rules="required"
              >
                <v-select
                  v-model="company"
                  @input="onChangeCompany"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companyList"
                  :reduce="(o) => o.value"
                  placeholder="Choisissez votre entreprise"
                  input-id="company"
                  :state="errors.length > 0 ? false : null"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pas de résultat pour <em>{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >Recherche une entreprise...</em
                    >
                  </template>
                </v-select>
                <small
                  v-for="(error, i) in errors"
                  :key="i"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
            <!-- email -->
            <b-form-group label-for="email" label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="votreemail@email.com"
                  autofocus
                />
                <small
                  v-for="(error, i) in errors"
                  :key="i"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Mot de Passe</label>
                <!-- <b-link :to="{ name: 'auth-forgot-password-v1' }">
                  <small>Mot de passe oublié?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Mot de passe"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Mot de passe"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-for="(error, i) in errors"
                  :key="i"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>

            <!-- checkbox
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Se Rappeler de Moi
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              class="mt-3"
              block
              :disabled="invalid || isLoading"
              @click="validationForm"
            >
              <b-spinner v-if="isLoading" label="Loading..." />
              <span v-else> Se Connecter </span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import * as companiesInfos from "@/utils/companiesInfos";

export default {
  name: "LoginForm",
  head: {
    title: "Connexion",
    titleTemplate: null,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      password: "",
      errorMessage: "",
      company: "",
      isLoading: false,

      // validation rules
      required,
      email,
      companyList: [
        { label: "SETEM", value: "setem" },
        { label: "SIMG", value: "simg" },
      ],
    };
  },
  created() {
    this.removeCompanyInfo();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginForm
        .validate()
        .then((success) => {
          if (!success) {
            throw new Error(
              "Une erreur est survenue lors de la validation des champs."
            );
          }

          let user = {
            email: this.userEmail,
            password: this.password,
          };

          this.isLoading = true;
          const response = store.dispatch("auth/login", user);

          return response;
        })
        .then((response) => {
          if (!response.success) {
            throw new Error(response.data?.error);
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Bienvenu sur le Progiciel Bêta",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur est survenue lors de l'authentification",
              icon: "CoffeeIcon",
              variant: "error",
            },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChangeCompany(company) {
      this.removeCompanyInfo();

      if (company != null) {
        localStorage.setItem(
          "COMPANY_INFO",
          JSON.stringify(companiesInfos[company])
        );
      }
    },
    removeCompanyInfo() {
      localStorage.removeItem("COMPANY_INFO");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
