var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("Progiciel")])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Bienvenu sur votre Progiciel Bêta 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Veuillez vous connecter à votre compte pour continuer ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorMessage))]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Entreprise","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.companyList,"reduce":function (o) { return o.value; },"placeholder":"Choisissez votre entreprise","input-id":"company","state":errors.length > 0 ? false : null},on:{"input":_vm.onChangeCompany},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" Pas de résultat pour "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Recherche une entreprise...")])]}}],null,true),model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_vm._l((errors),function(error,i){return _c('small',{key:i,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"email","label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"login-email","state":errors.length > 0 ? false : null,"placeholder":"votreemail@email.com","autofocus":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_vm._l((errors),function(error,i){return _c('small',{key:i,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Mot de Passe")])]),_c('validation-provider',{attrs:{"name":"Mot de passe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"login-password","placeholder":"Mot de passe"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_vm._l((errors),function(error,i){return _c('small',{key:i,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}],null,true)})],1),_c('b-button',{staticClass:"mt-3",attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid || _vm.isLoading},on:{"click":_vm.validationForm}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"label":"Loading..."}}):_c('span',[_vm._v(" Se Connecter ")])],1)],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }