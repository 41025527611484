export const simg = {
  "name": "SIMG",
  "full_name": "Société d'Ingénierie des Matériaux et de Géotechnique",
  "address": "Rue Tankpe, face Fontaine d'Eveil, Abomey Calavi",
  "contact": "+229 98 80 63 63",
  "logoPath": "/simg.jpg",
  "favico": "fav_simg.ico",
  "concerned_department": [
    {
      "label": "Géotechnique",
      "ref": "G",
    },
    {
      "label": "Contrôle",
      "ref": "C",
    },
    {
      "label": "Laboratoire",
      "ref": "LAB",
    },
    {
      "label": "Projet Spéciaux",
      "ref": "PS",
    },
    {
      "label": "Informatique",
      "ref": "INF",
    },
    {
      "label": "Location",
      "ref": "LOC",
    },
    {
      "label": "Autres Projets",
      "ref": "AP",
    },
  ]
};

export const setem = {
  "name": "SETEM",
  "full_name": "SETEM-BENIN",
  "address": "BP 299 Calavi Zopah X 21 L5 Benin",
  "contact": " (+229) 21 36 35 71 / (+229) 90 91 24 09",
  "logoPath": "/setem-benin.jpeg",
  "favico": "fav_setem.ico",
  "concerned_department": [
    {
      "label": "Hydraulique et Assainissement",
      "ref": "HA"
    },
    {
      "label": "Bâtiment Route et Génie Civil",
      "ref": "BTP"
    },
    {
      "label": "Aménagement hydro agricole et Barrage",
      "ref": "AHB"
    },
    {
      "label": "Projet Spéciaux",
      "ref": "PS"
    },
  ]
};

export const _ = null;